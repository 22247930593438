import queryString from 'query-string';
import { protectedResources } from '../authConfig';
// import { getAccessToken, getStarConnectAccessToken } from './AuthConfig';
import { getToken } from './token';
import { promises } from 'dns';

type AccessToken = 'fleet-insights' | 'star-connect';




const request = async (url: string, options: any, accessToken?: AccessToken) => {
  
  const requestHeaders = options.headers || new Headers();
  if (!requestHeaders.has('Content-Type')) {
    requestHeaders.append('Content-Type', 'application/json');
  }

  /*let apiToken;
  if (accessToken === 'star-connect') {
    apiToken = await getStarConnectAccessToken();
  } else {
    apiToken = await getAccessToken();
  }

  if (!apiToken) {
    // eslint-disable-next-line no-restricted-globals
    location.reload();
    return;
  }*/
  const token = await getToken(protectedResources.vass.scopes.read);
  requestHeaders.append('Authorization', `Bearer ${token}`);

  const updatedOptions = { ...options, headers: requestHeaders };
  
  return new Promise((resolve, reject) => {
    fetch(url, updatedOptions)
      .then((response: Response) => {
        if (!response.ok) {
          response
            .json()
            .then((json) => {
              reject({
                statusText: `${response.status}: ${response.statusText}`,
                message: json.message,
                statusCode: response.status,
                body: json,
              });
            })
            .catch((err) => {
              // Catching the error in reading the bad response
              reject({
                statusText: `${response.status}: ${response.statusText}`,
                message: err,
                statusCode: response.status,
              });
            });
        } else if (
          response.headers.has('Content-Type') &&
          (response.headers.get('Content-Type') || '').includes('application/json')
        ) {
          resolve(response.json());
        } else {
          resolve(response);
        }
        // TODO: If response is 401 -> Reauthenticate
      })
      .catch((e) => {        
        if(navigator.onLine)
        {
          reject(e);
        }
        else
          resolve(e);
      });
  });
};

const requestFile = async (url: string, options: any, accessToken?: AccessToken) => {
  const requestHeaders = options.headers || new Headers();
  const token = await getToken(protectedResources.vass.scopes.read);
  requestHeaders.append('Authorization', `Bearer ${token}`);
  requestHeaders.append('Accept', 'application/json');
  const updatedOptions = { ...options, headers: requestHeaders };

  return new Promise((resolve, reject) => {
    fetch(url, updatedOptions)
      .then((response: Response) => {
        if (!response.ok) {
          response
            .json()
            .then((json) => {
              reject({
                statusText: `${response.status}: ${response.statusText}`,
                message: json.message,
                statusCode: response.status,
                body: json,
              });
            })
            .catch((err) => {
              // Catching the error in reading the bad response
              reject({
                statusText: `${response.status}: ${response.statusText}`,
                message: err,
                statusCode: response.status,
              });
            });
        } else if (
          response.headers.has('Content-Type') &&
          (response.headers.get('Content-Type') || '').includes('application/json')
        ) {
          resolve(response.json());
        } else {
          resolve(response);
        }
        // TODO: If response is 401 -> Reauthenticate
      })
      .catch((e) => {
        if(navigator.onLine)
          {
            reject(e);
          }
          else
            resolve(e);
      });
  });
};

interface IOptions {
  queryParams?: any;
  accessToken?: AccessToken;
  signal?: AbortSignal;
  body?: any;
}

export const get: (url: string, options?: IOptions, data?: any) => Promise<any> = async (
  url,
  options,
  data,
) => {
  const { accessToken, queryParams, body, ...rest } = options || {};

  const getOptions = {
    method: 'GET',
    body: JSON.stringify(data),
    ...rest,
  };
  let enhancedUrl = url;
  if (queryParams) {
    enhancedUrl = enhancedUrl + `?${queryString.stringify(queryParams)}`;
  }
  return await request(enhancedUrl, getOptions, accessToken).catch((error) => {
    console.error('Request failed:', error)    
    // Resolve the promise to avoid throwing an error
    return Promise.resolve();
  });;
};

export const post: (url: string, body: any) => Promise<any> = async (url, body) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await request(url, options).catch((error) => {
    console.error('Request failed:', error)    
    // Resolve the promise to avoid throwing an error    
  
    if (navigator.onLine) {
      // Reject the promise when online
      return Promise.reject(error);
    } else {
      // Resolve the promise when offline
      return Promise.resolve();
    }
  });
};

export const fileApiPost: (url: string, body: any) => Promise<any> = (url, body) => {
  const options = {
    method: 'POST',
    body: body,
  };  
  return requestFile(url, options).catch((error) => {
    console.error('Request failed:', error)    
    // Resolve the promise to avoid throwing an error
    if (navigator.onLine) {
      // Reject the promise when online
      return Promise.reject(error);
    } else {
      // Resolve the promise when offline
      return Promise.resolve();
    }
  });
}
 
export const put: (url: string, body: any) => Promise<any> = (url, body) => {
  const options = {
    method: 'PUT',
    body: JSON.stringify(body),
  };
  return request(url, options).catch((error) => {
    console.error('Request failed:', error)    
    // Resolve the promise to avoid throwing an error
    return Promise.resolve();
  });
};
export const deleteAPI: (url: string, options?: IOptions, data?: any) => Promise<any> = (
  url,
  options,
  data,
) => {
  const { accessToken, queryParams, body, ...rest } = options || {};

  const getOptions = {
    method: 'DELETE',
    body: JSON.stringify(data),
    ...rest,
  };
  let enhancedUrl = url;
  if (queryParams) {
    enhancedUrl = enhancedUrl + `?${queryString.stringify(queryParams)}`;
  }
  return request(enhancedUrl, getOptions, accessToken).catch((error) => {
    console.error('Request failed:', error)    
    // Resolve the promise to avoid throwing an error
    return Promise.resolve();
  });

};
