// React
import React, { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

// Providers
import { MsalProvider } from '@azure/msal-react';
import AppProvider from './contextProviders/AppContextProvider';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from './authConfig';

// Styles
import './App.css';

// Components
import { SideNav } from './layouts/SideNav/SideNav';
import { TopNav } from './layouts/TopNav/TopNav';

// Pages
import { Login } from './pages/Auth/Login';
import { LoginCallback } from './pages/Auth/LoginCallback';
import { Provider } from 'react-redux';
import { persistor, store } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import NetworkStatus from './utils/PWA/NetworkStatus';
import useNetworkStatus from './utils/PWA/useNetworkStatus';

const Risks = React.lazy(() => import('./pages/Risks/components/RisksList/Risks'));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const Surveys = React.lazy(() => import('./pages/Surveys'));
const ShipInfo = React.lazy(() => import('./pages/ShipInfo'));
const EditQuestionnaire = React.lazy(
  () => import('./pages/Questionnaire/components/EditQuestionnaire/editQuestionnaire'),
);
const AddQuestionnaire = React.lazy(
  () => import('./pages/Questionnaire/components/AddQuestionnaire/addQuestionnaire'),
);
const Questionnaire = React.lazy(() => import('./pages/Questionnaire'));
const Question = React.lazy(() => import('./pages/Question'));
const PerformInspection = React.lazy(() => import('./pages/PerformInspection'));
const CreateSurvey = React.lazy(
  () => import('./pages/Surveys/components/SurveyDetails/CreateSurvey'),
);

function App({ msalInstance }: { msalInstance: any }) {
  const { pathname } = useLocation();
  const isOnline = useNetworkStatus();

    // Remove all entries that start with selectedTab_
    useEffect(() => {
      const prefix = 'selectedTab_';
      for (let i = 0; i < localStorage.length; i++) {
      
        const key = localStorage.key(i);
        if (key && key.startsWith(prefix)) {
          localStorage.removeItem(key);
          console.log(`Removed ${key} from localStorage`);
        }
      }
    }, []);
    
  const authRequest = {
    ...loginRequest,
  };

  return (
    <>
      {isOnline && msalInstance && msalInstance ? (
        <MsalProvider instance={msalInstance}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
          >
            <Provider store={store}>
              <PersistGate persistor={persistor}>
                <AppProvider>
                  <div>
                    <div className='header'>
                      <TopNav selectedNav={window.location.pathname}></TopNav>
                    </div>
                    <div className='menu sidenav-container'>
                      <SideNav selectedNav={window.location.pathname} />
                    </div>
                    <div className='main'>
                      <NetworkStatus />
                    </div>
                    <div className='main'>
                      <Suspense fallback={<div>Loading...</div>}>
                        <Routes>
                          <Route path='*' element={<Dashboard />} />
                          <Route path='/dashboard' element={<Dashboard />} />
                          <Route path='/question' element={<Question />} />
                          <Route path='/risks' element={<Risks />} />
                          <Route path='/survey-types' element={<Surveys />} />
                          <Route path='survey-types/create-survey-type' element={<CreateSurvey />} />
                          <Route path='survey-types/edit-survey-type/:surveyTypeId' element={<CreateSurvey />} />
                          <Route path='/questionnaire' element={<Questionnaire />} />
                          <Route path='questionnaire/add-questionnaire' element={<AddQuestionnaire />} />
                          <Route path='questionnaire/edit-questionnaire/:questionnaireId' element={<EditQuestionnaire />} />
                          <Route path='/Shipinfo/:shipName/:imo' element={<ShipInfo />} />
                          <Route path='/perform-inspection' element={<PerformInspection />} />
                          <Route path='/login-callback' element={<LoginCallback />} />
                          <Route path='/login' element={<Login />} />
                          {/* Default Route */}
                          <Route path="/" element={<Navigate to="/dashboard" />} />
                          <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                      </Suspense>
                    </div>
                  </div>
                </AppProvider>
              </PersistGate>
            </Provider>
          </MsalAuthenticationTemplate>
        </MsalProvider>
      ) : (
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <AppProvider>
              <div>
                <div className='header'>
                  <TopNav selectedNav={window.location.pathname}></TopNav>
                </div>
                <div className='menu sidenav-container'>
                  <SideNav selectedNav={window.location.pathname} />
                </div>
                <div className='main'>
                  <NetworkStatus />
                </div>
                <div className='main'>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                      <Route path='*' element={<Dashboard />} />
                      <Route path='/dashboard' element={<Dashboard />} />
                      <Route path='/question' element={<Question />} />
                      <Route path='/risks' element={<Risks />} />
                      <Route path='/survey-types' element={<Surveys />} />
                      <Route path='survey-types/create-survey-type' element={<CreateSurvey />} />
                      <Route path='survey-types/edit-survey-type/:surveyTypeId' element={<CreateSurvey />} />
                      <Route path='/questionnaire' element={<Questionnaire />} />
                      <Route path='questionnaire/add-questionnaire' element={<AddQuestionnaire />} />
                      <Route path='questionnaire/edit-questionnaire/:questionnaireId' element={<EditQuestionnaire />} />
                      <Route path='/Shipinfo/:shipName/:imo' element={<ShipInfo />} />
                      <Route path='/perform-inspection' element={<PerformInspection />} />
                      <Route path='/login-callback' element={<LoginCallback />} />
                      <Route path='/login' element={<Login />} />
                      {/* Default Route */}
                      <Route path="/" element={<Navigate to="/dashboard" />} />
                      <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                  </Suspense>
                </div>
              </div>
            </AppProvider>
          </PersistGate>
        </Provider>
      )}
    </>
  );
}

export default App;